import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout';
import 'react-responsive-modal/styles.css';
import Seo from '../components/seo';

export const query = graphql`
    query($id: ID!) {
        wpcontent {
            productUpdate(id: $id) {
                title
                uri
                content
                excerpt
                coverImage
                date
                seo {
                    metaDesc
                    canonical
                    title
                    opengraphTitle
                    opengraphDescription
                    opengraphType
                    twitterTitle
                    twitterDescription
                }
            }
        }
    }
`

const productUpdateTemplate = ({ data }) => {
 
    const productUpdate = data.wpcontent.productUpdate


    const metaDesc = data.wpcontent.productUpdate.seo.metaDesc
    const pageTitle = data.wpcontent.productUpdate.seo.title
    const publishedDate = new Date().toDateString(data.wpcontent.productUpdate.date)
    // const pageType = data.wpcontent.productUpdate.seo.opengraphType

    // let pageurl = `https://www.vantagecircle.com`
    // const uri = productUpdate.uri
    // if(uri){
    //     pageurl+= `${uri}`
    // }

    return (

        <Layout className="webinar-detail">
            <Seo title={pageTitle}
                description={metaDesc}
                
            />
            <section className="mx-auto py-10 pb-5 sm:py-20 sm:pb-20 relative">
                <div className="container max-w-5xl sm:text-center px-6 sm:px-6 lg:px-6">
                    <div className="site-breadcrumb md:flex justify-center sm:text-center mb-5 text-gray-200">
                        <Link className="hover:underline" to="/">Home</Link>&nbsp;>>&nbsp;
                        <Link className="hover:underline" to="/product-updates/">Product Updates</Link>&nbsp;>>&nbsp; 
                        <span className="" dangerouslySetInnerHTML={{ __html: productUpdate.title }} />
                    </div>
                    <h1 className="hero-title-2 text-center">{productUpdate.title}</h1>
                    <div className = "w-full md:flex justify-center mt-8">
                        <div className = "md:flex flex-col lg:flex-row w-full lg:w-4/6 justify-around">
                            <a className = "flex items-center justify-center text-base text-center " href = "/product-updates/">Product Updates</a>
                            <p className = " items-center hidden lg:flex text-base  text-center justify-center mb-2">.</p>
                            <p className = "flex items-center justify-center text-base  text-center mb-0">4 MIN READ</p>
                            <p className = " items-center hidden lg:flex text-base  text-center justify-center mb-2">.</p>
                            <p className = "flex items-center justify-center text-base  text-center mb-0">Last Updated On {publishedDate}</p>
                        </div>
                    </div>
                </div>
                
            </section>

            <section className = "w-full py-6 sm:py-0 sm:pb-20 flex flex-col items-center">
                <div className = "flex md:w-7/12 justify-center px-6">
                    <img className = "rounded-xl shadow-lg" src ={productUpdate.coverImage} alt={productUpdate.title}/>
                </div>
            </section>
            {/* <div className=" py-2 sm:py-5 md:py-8 lg:py-10"><div className="">&nbsp;</div></div> */}
            {/* <section className="container max-w-7xl px-6 lg:px-8 pb-20 md:-mt-36">
                <div>
                    <div className="bg-white block md:flex card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out">
                        <div className="w-full md:w-3/5">
                            <img className="p-5" src="" alt={productUpdate.title} />
                        </div>
                    </div>
                </div>
            </section>  */}

            <div className="w-full flex justify-center blog-updates">
                <div className="w-5/6 lg:w-6/12 flex flex-col">
                    {/* <section className = "w-full flex justify-center">
                        <div className = "w-5/6 lg:w-5/12 flex flex-col bg-purple-100 rounded-lg p-9">
                            <div class="table_heading text-purple-500 text-xl"><b>TABLE OF CONTENTS</b></div>
                            <div class="table_subheading font-bold text-gray-800 mt-6"><b>What does the update contain?</b></div>
                            <ul className = "flex flex-col mt-3">
                                <li className = "font-bold text-gray-800 mb-2"><a href = "#delegation" className = "text-gray-800"><span className ="text-purple-500">1. </span>&nbsp;&nbsp;Delegation</a></li>
                                <li className = "font-bold text-gray-800 mb-2"><a href = "#delegation" className = "text-gray-800"><span className ="text-purple-500">2. </span>&nbsp;&nbsp;Language Support</a></li>
                                <li className = "font-bold text-gray-800 mb-2"><a href = "#delegation" className = "text-gray-800"><span className ="text-purple-500">3. </span>&nbsp;&nbsp;Super Admin Access</a></li>
                                <li className = "font-bold text-gray-800 mb-2"><a href = "#delegation" className = "text-gray-800"><span className ="text-purple-500">1. </span>&nbsp;&nbsp;Delegation</a></li>
                                <li className = "font-bold text-gray-800 mb-2"><a href = "#delegation" className = "text-gray-800"><span className ="text-purple-500">2. </span>&nbsp;&nbsp;Language Support</a></li>
                                <li className = "font-bold text-gray-800 mb-2"><a href = "#delegation" className = "text-gray-800"><span className ="text-purple-500">3. </span>&nbsp;&nbsp;Super Admin Access</a></li>
                            </ul>
                        </div>
                    </section> */}
                    <div dangerouslySetInnerHTML={{ __html: productUpdate.content }} />
                </div>
            </div>
           
            {/* <section className="bottom-cta m-6 md:m-0 relative z-1">
                <div className="container max-w-5xl bg-purple-300 rounded-lg shadow-xl p-10 md:p-14 relative z-9 overflow-hidden md:-bottom-10">
                    <div  className="bottom-cta-content text-center text-white">
                        <div className="section-title-white">Find Out How Vantage Circle Works</div>
                        <div className="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                            <div>
                                <a className="vc-ghost-btn-cta-banner" href="/request-demo/">Schedule a Free Demo</a>
                            </div>
                        </div>
                    </div>
                    <img loading="lazy" className="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" className="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section> */}
        </Layout>
    )
}

export default productUpdateTemplate